import {useState} from 'react';
import {
  artBook,
  baAndProj,
  cat1,
  greenLogo,
  first,
  karmo,
  magazine,
  marmot1,
  marmot2,
  marmot3,
  ornament,
  samurai,
  sm1,
  sm2,
  sm3,
  sm4,
  smLogo,
  timeline,
  trajan,
  wd1,
  wd2,
  cvPart2,
  cvPart1
} from './constants';
import {mainImage} from './constants';
import {Card, Card2} from './Card';

function App() {
  const [cv, setCV] = useState(false);

  const navbarStyle = `
    p-4
    pl-24
    pr-24
    align-center
    flex
    flex-row
    justify-between
    h-20
    ${cv ? 'bg-stela-orange' : 'bg-stela-purple'}
    ${cv ? 'bg-opacity-100' : 'bg-opacity-20'}
    ${cv ? 'text-stela-light-green' : 'text-stela-green'}
  `
  const footerStyle = `
    p-4
    pl-24
    pr-24
    ${cv ? 'bg-stela-orange' : 'bg-stela-light-green'}
    h-32
    flex
    flex-row
    align-middle
    justify-between
    ${cv && 'text-stela-light-green'}
  `

  const Portfolio = () => (
    <>
      <img  src={mainImage} className='w-9/12 h-9/12 self-center align-center' alt='main' />
      <div className='w-full flex flex-row justify-center bg-stela-purple bg-opacity-20'>
        <img src={first} className='w-9/12 h-9/12 self-center' alt='first' />
      </div>
      <div className='w-full flex flex-row justify-center bg-stela-purple bg-opacity-20'>
        <img className='w-9/12 h-9/12 self-center' src={timeline} alt='timeline' />
      </div>
      <div>
        {[magazine, baAndProj, ornament, trajan, samurai, karmo,].map((image) => <Card key={image} image={image} />)}
        {[artBook, marmot1, marmot2, marmot3].map((image) => <Card2 key={image} image={image} marmot />)}
        {[cat1, wd1, wd2].map((x) => <Card2 key={x} image={x} />)}
        {[sm1, sm2, sm3, sm4].map((x) => <Card2 key={x} image={x} marmot={false} />)}
      </div>
    </>
  );

  const CV = () => (
    <div className='flex flex-col w-full self-center bg-orange-filter bg-opacity-20'>
      <img className='self-center w-6/12' src={cvPart1} alt="cv2" />
      <img className='self-center w-6/12' src={cvPart2} alt="cv2" />
    </div>
  );

  return (
    <div className='flex flex-col justify-center font-sansita w-full'>
      <div className={navbarStyle}>
        <img src={cv ? smLogo : greenLogo} className='h-12' alt='logo' />
        <div className='flex flex-row self-center gap-4 text-base'>
          <p
            className='hover:cursor-grab hover:opacity-75'
            onClick={() => setCV(!cv)}
          >
            {cv ? 'PORTFOLIO' : 'CV'}
          </p>
          <p
            className='hover:cursor-grab hover:opacity-75'
            onClick={() => {
              const anchor = document.querySelector('#git');
              anchor?.scrollIntoView({behavior: 'auto', block: 'center'})
            }}
          >
            GET IN TOUCH
          </p>
        </div>
      </div>
      {!cv ? <Portfolio /> : <CV />}
      <div id="footer" className={footerStyle}>
        <img src={cv ? smLogo : greenLogo} className='self-center h-20' alt="logo" />
        <p id="git" className='self-center'>GET IN TOUCH: <br /> stela.markova@gmail.com</p>
      </div>
    </div>
  );
}

export default App;
