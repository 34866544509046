import {BAPurpleSideBar, MarmotSideBar, SMSideBar, WebDesignSideBar} from './constants'

export const Card = ({image}: {image: string}) => {
    return (
        <div className='flex flex-row justify-between items-center'>
            <img className='mr-auto ml-auto w-10/12' src={image} alt="ornament" />
            <img className='ml-auto w-[4%]' src={BAPurpleSideBar} alt="BA sidebar" />
        </div>
    )
}

export const Card2 = ({image, marmot}: {image: string, marmot?: boolean}) => {
    return (
        <div className='flex flex-row w-full justify-between items-center'>
            <img className='w-10/12 mr-auto ml-auto' src={image} alt="ornament" />
            <img className='w-[4%] ml-auto' src={marmot === true ? MarmotSideBar : marmot === false ? SMSideBar : WebDesignSideBar} alt="marmot sidebar" />
        </div>
    )
}
