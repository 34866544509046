export const smLogo = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361084/Stela-portfolio-website/CM__white_cb2jjm.png';
export const mainImage = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361085/Stela-portfolio-website/cover_zr8lun.png';
export const timeline = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1684060368/Stela-portfolio-website/About_me_ydkgni.png';
export const magazine = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683537301/Stela-portfolio-website/1_ihs4hm.png';
export const baAndProj = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361085/Stela-portfolio-website/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%BE%D0%BB%D0%B8%D0%BE3_u9e6sf.png';
export const ornament = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361100/Stela-portfolio-website/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%BE%D0%BB%D0%B8%D0%BE4_vfaivr.png';
export const trajan = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361087/Stela-portfolio-website/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%BE%D0%BB%D0%B8%D0%BE5_hirmut.png';
export const samurai = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361100/Stela-portfolio-website/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%BE%D0%BB%D0%B8%D0%BE6_cvmpon.png';
export const karmo = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361104/Stela-portfolio-website/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%BE%D0%BB%D0%B8%D0%BE8_qypv4u.png';
export const artBook = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361108/Stela-portfolio-website/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%BE%D0%BB%D0%B8%D0%BE11_kd6ubv.png';
export const marmot1 = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361091/Stela-portfolio-website/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%BE%D0%BB%D0%B8%D0%BE15_yw4ugi.png';
export const marmot2 = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361101/Stela-portfolio-website/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%BE%D0%BB%D0%B8%D0%BE16_f71rnu.png';
export const marmot3 = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361101/Stela-portfolio-website/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%BE%D0%BB%D0%B8%D0%BE17_fzz5tx.png';
export const cat1 = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361100/Stela-portfolio-website/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%BE%D0%BB%D0%B8%D0%BE21_tl0wke.png';
export const wd1 = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361091/Stela-portfolio-website/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%BE%D0%BB%D0%B8%D0%BE22_xwgihf.png'
export const wd2 = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361092/Stela-portfolio-website/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%BE%D0%BB%D0%B8%D0%BE23_cw0zbo.png'
export const sm1 = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361092/Stela-portfolio-website/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%BE%D0%BB%D0%B8%D0%BE27_p5ghou.png'
export const sm2 = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361087/Stela-portfolio-website/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%BE%D0%BB%D0%B8%D0%BE28_obkisq.png'
export const sm3 = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361091/Stela-portfolio-website/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%BE%D0%BB%D0%B8%D0%BE30_twzbw8.png'
export const sm4 = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361096/Stela-portfolio-website/%D0%BF%D0%BE%D1%80%D1%82%D1%84%D0%BE%D0%BB%D0%B8%D0%BE29_qfzi1k.png'
export const greenLogo = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683539604/Stela-portfolio-website/CM__green1_rhucvp.png'
export const first = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361085/Stela-portfolio-website/contents_jgcwl3.png'
export const cvPart1 = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683977375/Stela-portfolio-website/CV_en_2023_page_1_gxy57v.png';
export const cvPart2 = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683977184/Stela-portfolio-website/CV_en_2023_page_2_fk6lb5.png';
export const cvPageBgFilter = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683361084/Stela-portfolio-website/Rectangle_12_gzea2h.png';
export const BAPurpleSideBar = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683985035/Stela-portfolio-website/BA_Nav_ms8ryl.png';
export const MarmotSideBar = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683985035/Stela-portfolio-website/Intership_nav_yaaqoi.png';
export const WebDesignSideBar = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683985035/Stela-portfolio-website/web_nav_kqydxb.png';
export const SMSideBar = 'https://res.cloudinary.com/dhhrvlvjb/image/upload/v1683985035/Stela-portfolio-website/social_nav_gd4m3q.png';
